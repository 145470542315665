import React from 'react'

export const PageDescription = ({ description }) => {
  return (
    <div className="row my-3">
      <div className="col-md-12">
        <p className="my-0">{description}</p>
      </div>
    </div>
  )
}

export default PageDescription